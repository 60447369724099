import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';

function Kofi() {
  return (
    <Link href="https://ko-fi.com/tatertechstudios">
      <img src={"https://storage.ko-fi.com/cdn/nav-logo-stroke.png"} alt={"KoFi logo"}/>
     </Link>
  );
}

function Discord() {
  return (
    <Link href="https://dsc.gg/tater" className="button">
      <img src={"discord-mark-white.svg"} alt="Discord logo"/>
     </Link>
  );
}

export default function Footer() {
  const [value, setValue] = React.useState(0);

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Box>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Ko-Fi" icon={<Kofi />} />
          <BottomNavigationAction label="Discord" icon={<Discord />} />
        </BottomNavigation>
      </Box>
    </Paper>
  );
}
