import { Route, Routes } from "react-router-dom";
import './App.css';
import Container from '@mui/material/Container';
import LinearWithValueLabel from './LinearWithValueLabel';
import Footer from './Footer'
import Twitch from './Twitch'

function Main() {
  return (
    <Container fixed sx={{ textAlign: 'center' }}>
      <div className="App-header">
        <h5>
           We'll be back to finish our site after we complete development on our 90's inspired racing game:
        </h5>
        <div className="MainTitle">Element Racers:</div>
        <div className="SubTitle">Veggie Vendetta</div>
      </div>

      <div className="ProgressBars">
        <h3>Current Progress - As of December 19 2024</h3>
        <LinearWithValueLabel limit={57.97} name="Coding" color="secondary"/>
        <LinearWithValueLabel limit={69.4} name="Music" color="primary"/>
        <LinearWithValueLabel limit={52} name="2D" color="success"/>
        <LinearWithValueLabel limit={63.16} name="3D" color="inherit"/>
      </div>
      <Twitch></Twitch>
      <Footer></Footer>
    </Container>
   )
}

function App() {
  return (
    <Routes>
      <Route path='/' element={Main()} />
      <Route path='/live' Component={() => {
        window.location.replace('https://twitch.tv/tatertechstudios');
      }}/>
     </Routes>
  );
};

export default App;
