import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 100 }}>
        <Typography variant="body2" sx={{ color: `${props.color}` }}>
          {`${props.name} - ${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  name:  PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default function LinearWithValueLabel(props) {
  const [progress, setProgress] = React.useState(0);
  const timeoutID = useRef(null);
  const limit = props.limit;

  React.useEffect(() => {
    timeoutID.current = setInterval(() => {
      if (progress < limit) {
        setProgress(progress => ( progress + 0.1 ));
      } else {
        clearInterval(timeoutID.current);
        timeoutID.current = null;
        return;
      }
    }, 1);

    return () => {
      clearInterval(timeoutID.current);
    };
  }, [progress, limit]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} limit={limit} name={props.name} color={props.color} />
    </Box>
  );
}