import React, { useRef } from 'react';
import { TwitchPlayer } from 'react-twitch-embed';

const Twitch = () => {
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.
  
  const handleReady = (e) => {
    embed.current = e;
  };
  
  return (
    <TwitchPlayer channel="tatertechstudios" autoplay muted onReady={handleReady} width={"75%"}/>
  );
};

export default Twitch;